<script setup lang="ts">
import { useSlots } from "vue"

interface Props {
  sidebar?: boolean // for layouts with sidebars
  constrain?: boolean // for content that doesnt need horizontal space
  center?: boolean // to center the layout
}

const props = withDefaults(defineProps<Props>(), {
  sidebar: false,
  constrain: false,
  center: false,
})

const slots = useSlots()

const wrapperClasses = computed(() => {
  const classes = [
    props.constrain ? "max-w-4xl" : "max-w-9xl",
    props.center ? "mx-auto" : "",
  ]
  return classes.join(" ")
})
</script>

<template>
  <div class="p-8">
    <div class="max-w-9xl mx-auto">
      <div :class="wrapperClasses">
        <div v-if="sidebar">
          <div v-if="slots.overview" class="flex-1">
            <slot name="overview"></slot>
          </div>
          <div class="flex gap-4">
            <div class="flex-1 min-w-0">
              <slot></slot>
            </div>
            <div v-if="slots.sidebar" class="min-w-60 max-w-xs">
              <slot name="sidebar"></slot>
            </div>
          </div>
        </div>
        <div v-else>
          <slot></slot>
        </div>
      </div>
    </div>
  </div>
</template>
